import React, { useRef, useState } from "react"
import { Localized } from "../../../../packages/localization/Localized"
import { Image } from "../../../../reactor/Types/File"
import { DateString, Email, Markdown, PhoneNumber } from "../../../../reactor/Types/Primitives"
import { PartyRoomByVenue, PartyMeal, PartyPackageByVenue, PartyPrice } from "../client"
import { PartyContext } from "./party-booker2/PartyContext"
import { StartPage } from "./party-booker2/StartPage"
import { RoomAndTimePage } from "./party-booker2/RoomAndTimePage"
import { PartyPackagePage } from "./party-booker2/PartyPackagePage"
import { PartyExtrasPage } from "./party-booker2/PartyExtrasPage"
import { ContactInfoPage } from "./party-booker2/ContactInfoPage"
import { SummaryPage } from "./party-booker2/SummaryPage"
import { MinuteOfDay } from "../../primitives/TimeSlot"
import { ConfirmationPage } from "./party-booker2/ConfirmationPage"
import { usePageContext } from "../Page"
import { Route, Routes } from "react-router-dom"
import { PaymentPage } from "./party-booker2/PaymentPage"
import { Section } from "../../../../packages/editing/Section"
import { PartyMealPage } from "./party-booker2/PartyMealPage"

export type PartyBooker2 = {
    /**
     * @default '{"en": "It's Party Time!"}'
     */
    headerText: Localized<string>
    /**
     * @default '{"en":"We look forward to welcoming you"}'
     */
    headerSubtext: Localized<string>

    chooseAVenue: Localized<string>

    /**
     * @default '{"en":"Place"}'
     */
    buttonPrefix: Localized<string>

    /**
     * @default '{"en":"Guests"}'
     */
    guestsText: Localized<string>
    /**
     * @default '{"en":"Number of children"}'
     */
    guestsExplanation: Localized<string>

    /** The text on the button to proceed to next step after selecting location,
     * date and number of guests. */
    callToActionOne: Localized<string>

    /**
     * Text after the price per child, e.g. "per child"
     * @tab Package Selection
     */
    perChildText: Localized<string>

    selectedText: Localized<string>

    packagePickerTitle: Localized<string>
    packagePickerSubtitle: Localized<Markdown>

    packagePickerSticker?: Image
    packagePickerStickerOffsetX: number
    packagePickerStickerOffsetY: number

    callUsToBookPackage: Localized<string>

    mealOptions: Localized<string>
    mealPickerSubtitle: Localized<Markdown>

    mealPickerSticker?: Image
    mealPickerStickerOffsetX: number
    mealPickerStickerOffsetY: number

    whoAreWeCelebrating: Localized<string>
    yourInformation: Localized<string>
    contactInfoSubtext: Localized<Markdown>

    contactInfoSticker?: Image
    contactInfoStickerOffsetX: number
    contactInfoStickerOffsetY: number

    yourInformationSticker?: Image
    yourInformationStickerOffsetX: number
    yourInformationStickerOffsetY: number

    partyExtras: Localized<string>
    partyExtrasSubtext: Localized<Markdown>
    partyExtrasSubheader: Localized<string>
    partyExtrasSubheaderSubtext: Localized<Markdown>

    partyExtrasSticker?: Image
    partyExtrasStickerOffsetX: number
    partyExtrasStickerOffsetY: number

    /**
     * Label for the name of the birthday child textbox.
     */
    nameOfTheBirthdayChild: Localized<string>

    summaryThanks: Localized<string>
    backToHomePage: Localized<string>

    summarySticker?: Image
    summaryStickerOffsetX: number
    summaryStickerOffsetY: number

    /**
     * Label for the birthday child input box.
     */
    birthDate: Localized<string>

    /**
     * Labels for the contact information fields.
     */
    informationFields: {
        firstName: Localized<string>
        lastName: Localized<string>
        email: Localized<string>
        phoneNumber: Localized<string>
        notes: Localized<string>
    }

    summaryPage: {
        header: Localized<string>
        subtext: Localized<string>
        receiptInformation: Localized<string>
        birthdayChild: Localized<string>
        dateAndTime: Localized<string>
        roomAndLocation: Localized<string>
        numberOfChildren: Localized<string>
        packageAndAddons: Localized<string>
        notes: Localized<string>
        iAcceptTermsAndConditions: Localized<string>
        includingVAT: Localized<string>
    }

    footer: {
        priceDetails: Localized<string>
        confirmAndPay: Localized<string>
        nextText: Localized<string>
    }

    breadcrumbs: {
        date: Localized<string>
        roomAndTime: Localized<string>
        partyPackage: Localized<string>
        addons: Localized<string>
        contactInfo: Localized<string>
        summary: Localized<string>
    }

    iHaveReadAndAcceptTOC: Localized<Markdown>

    confirmationHeader: Localized<string>
    confirmationText: Localized<Markdown>
    referenceText: Localized<string>
    confirmationImage?: Image

    payment?: Localized<string>
}

Section("PartyBooker2", PartyBooker2)
export function PartyBooker2(section: PartyBooker2) {
    const minimumGuests = 6

    const page = usePageContext()

    const [guestCount, setGuestCount] = useState(minimumGuests)
    const [date, setDate] = useState<Date | undefined>()
    const [partyPackage, setPartyPackage] = useState<PartyPackageByVenue | undefined>()
    const [mealOption, setMealOption] = useState<PartyMeal | undefined>()
    const [room, setRoom] = useState<PartyRoomByVenue | undefined>()
    const [roomName, setRoomName] = useState<string | undefined>()
    const [time, setTime] = useState<MinuteOfDay | undefined>()
    const [extras, setExtras] = useState<{ [id: string]: number } | undefined>()
    const [nameOfTheBirthdayChild, setNameOfTheBirthdayChild] = useState("")
    const [birthDate, setBirthDate] = useState<DateString | undefined>()

    // Contact info of parent
    const [firstName, setFirstName] = useState<string | undefined>()
    const [lastName, setLastName] = useState<string | undefined>()
    const [email, setEmail] = useState<Email | undefined>()
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | undefined>()
    const [notes, setNotes] = useState<string | undefined>()

    const [acceptedTOC, setAcceptedTOC] = useState(false)
    const priceRef = useRef<PartyPrice | undefined>(undefined)

    return (
        <PartyContext.Provider
            value={{
                section,
                minimumGuests,
                guestCount,
                setGuestCount,
                date,
                setDate,
                partyPackage,
                mealOption,
                setPartyPackage,
                setMealOption,
                room,
                roomName,
                time,
                extras,
                setExtras,
                setExtra(id, quantity) {
                    setExtras((prev) => ({ ...prev, [id.valueOf()]: quantity }))
                },
                setRoomAndTime(room2, name, time2) {
                    setRoom(room2)
                    setRoomName(name)
                    setTime(time2)
                },
                birthDate,
                setBirthDate,
                venues: page.venues,
                nameOfTheBirthdayChild,
                setNameOfTheBirthdayChild,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                email,
                setEmail,
                phoneNumber,
                setPhoneNumber,
                notes,
                setNotes,
                acceptedTOC,
                setAcceptedTOC,
                priceRef,
            }}
        >
            <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="room-and-time" element={<RoomAndTimePage />} />
                <Route path="package" element={<PartyPackagePage />} />
                <Route path="meal" element={<PartyMealPage />} />
                <Route path="extras" element={<PartyExtrasPage />} />
                <Route path="contact-info" element={<ContactInfoPage />} />
                <Route path="summary" element={<SummaryPage />} />
                <Route path="payments/:adyenOrder/:adyenOrderId" element={<PaymentPage />} />
                <Route
                    path="confirmation/:adyenOrder/:adyenOrderId"
                    element={<ConfirmationPage />}
                />
            </Routes>
        </PartyContext.Provider>
    )
}
