import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"

export type SaveListener = {
    discard(): Promise<void>
    save(): Promise<void>
    isDirty: boolean
}

export type EditableResource = {
    query: string
    endpoint: string
    data: any
    dtoName: string
    putDtoName: string
}

export type EditableContext = {
    /**
     * Notifies the context that something may have changed in the resource(s)
     * currently being edited.
     *
     * This will trigger all the change listeners to be probed for dirty states.
     * The result could either be that the resources are now dirty, or that they
     * are no longer dirty (set back to their original state).
     *
     */
    invalidate(): void
    saveListeners: Set<SaveListener>

    /** Sets whether we are in edit mode.
     *
     *  This is typically toggled by the Edit/View switch in the WYSIWYG toolbar.
     *
     */
    setEditing(editing: boolean): void

    /**
     * Can be set by editing components when they are focused, to let the
     * editable context know that we are currently editing something.
     *
     * This information can be used to e.g. disable resource refreshing, that
     * would otherwise happen per-keystroke.
     */
    setFocused(focused: boolean): void

    save(forceDirty?: boolean): Promise<void>

    discardChanges(): Promise<void>
    addSaveListener(callback: SaveListener): void
    removeSaveListener(callback: SaveListener): void
}

const DummyEditableContext: EditableContext = {
    invalidate() {},
    saveListeners: new Set(),
    setEditing(editing: boolean) {},
    setFocused(focused: boolean) {},

    async save(forceDirty?: boolean) {},

    async discardChanges() {},

    addSaveListener(callback: SaveListener) {},
    removeSaveListener(callback: SaveListener) {},
}

const EditableContext = createContext<EditableContext | null>(null)

export function DummyEditableContextProvider({ children }: { children: React.ReactNode }) {
    return (
        <EditableContext.Provider value={DummyEditableContext}>{children}</EditableContext.Provider>
    )
}

/**
 * Returns the current EditableContext.
 *
 * This is a set of fixed callbacks that can be used to interact with the
 * current editable context. This context should never change, so it does not
 * cause any re-renders to your component.
 *
 * If you want to monitor changes to the editable context, you should use the
 * individual state hooks, like `useIsEditing`, `useIsDirty`, `useIsFocused`,
 * etc. This way, your component will only be invalidated when the specific
 * state you are interested in changes.
 */
export function useEditableContext() {
    const context = useContext(EditableContext)
    if (!context) throw new Error("No editable context")
    return context
}

export function useEditableContextIfExists() {
    return useContext(EditableContext)
}

/** Registers a function that will be called when the Save button is clicked. */
export function useSaveHook(save: SaveListener, ...deps: any[]) {
    const { addSaveListener, removeSaveListener } = useEditableContext()
    useEffect(() => {
        addSaveListener(save)
        return () => removeSaveListener(save)
    }, [addSaveListener, removeSaveListener, save, ...deps])
}

export type EditableOptions = {
    /** Whether this hook is enabled. Defaults to true */
    condition?: boolean
    /** Whether the current user can "set" data through this hook. Defaults to requires super user.
     * */
    canWrite?: boolean
    /** Whether the current user can "get" this hook. Defaults to true for all users. */
    canRead?: boolean
}

const IsEditingContext = createContext(false)
const IsFocusedContext = createContext(false)
const VersionKeyContext = createContext<string | null>(null)
const IsDirtyContext = createContext(false)

/**
 * Returns whether the website is currently in a "dirty" state, i.e. the user has
 * made changes that have not been saved.
 */
export function useIsDirty() {
    return useContext(IsDirtyContext)
}

/**
 * Returns whether the website is currenlty in "Edit" mode, i.e. the user has
 * clicked the "Edit" toggle in the WYSIWYG toolbar.
 */
export function useIsEditing() {
    return useContext(IsEditingContext)
}

/**
 * Returns whether the website is currently focused on an editable component.
 */
export function useIsFocused() {
    return useContext(IsFocusedContext)
}

/**
 * Returns the current version key of the editable context.
 *
 * This key is used to force a re-render of all components that depend on the
 * editable context, when the context changes.
 */
export function useVersionKey() {
    return useContext(VersionKeyContext)
}

/**
 * Default implementation of EditableContext
 */
export function EditableContextProvider({ children }: { children: React.ReactNode }) {
    const [version, setVersion] = useState({})
    const [versionKey, setVersionKey] = useState(() => Uuid())
    const [editing, setEditing] = useState(false)

    const saveListeners = useRef(new Set<SaveListener>()).current

    function getIsDirty() {
        return Array.from(saveListeners).some((listener) => listener.isDirty)
    }

    const addSaveListener = useCallback((listener: SaveListener) => saveListeners.add(listener), [])
    const removeSaveListener = useCallback(
        (listener: SaveListener) => saveListeners.delete(listener),
        []
    )
    const discardChanges = useCallback(async () => {
        // Need to snapshot the set, in case the save operation modifies
        // the set of listeners.
        const snapshotListeners = Array.from(saveListeners)
        for (const listener of snapshotListeners) {
            await listener.discard()
        }
        setVersionKey(Uuid())
        setVersion({})
    }, [])

    const save = useCallback(async (forceDirty?: boolean) => {
        if (getIsDirty() || forceDirty) {
            // Need to snapshot the set, in case the save operation modifies
            // the set of listeners.
            const snapshotListeners = Array.from(saveListeners)
            for (const listener of snapshotListeners) {
                await listener.save()
            }
        }
        // Need to allow a re-render before checking the isDirty state again
        setTimeout(() => setVersion({}), 1)
    }, [])
    const invalidate = useCallback(() => setVersion({}), [])

    const [focused, setFocused] = useState(false)

    const ec = useMemo(() => {
        const res: EditableContext = {
            saveListeners,
            setFocused,
            setEditing,
            invalidate,
            save,
            addSaveListener,
            removeSaveListener,
            discardChanges,
        }
        return res
    }, [])

    return (
        <IsDirtyContext.Provider value={getIsDirty()}>
            <VersionKeyContext.Provider value={versionKey.valueOf()}>
                <IsEditingContext.Provider value={editing}>
                    <IsFocusedContext.Provider value={focused}>
                        <EditableContext.Provider value={ec}>{children}</EditableContext.Provider>
                    </IsFocusedContext.Provider>
                </IsEditingContext.Provider>
            </VersionKeyContext.Provider>
        </IsDirtyContext.Provider>
    )
}
