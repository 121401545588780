import { useCallback, useEffect, useRef, useState } from "react"
import { TypeScript } from "../../../reactor/Types/TypeScript"
import { postSendMailParseAndEvaluate, postSendMailPrettifyCode } from "../../client"
import type { StudioTrigger } from "../StudioTriggers"
import { server } from "../../../server"
import type { SendMail } from "./SendMail"
import { EmailTemplate } from "../../../packages/email/EmailTemplate"
import { SerializeAST } from "../../../packages/ast/ASTSerializer"
import { useBoundState } from "../../../packages/editing/useBoundState"
import { ColorStyles } from "../../../packages/ui"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"
import { useDocument } from "../../Views/DocumentContext"
import { Assert } from "../../../reactor"

/**
 * Version counter to make sure only the latest change is applied,
 * as they need to round-trip through the server for prettification.
 */
let updateVersion = 0

/**
 * This is the Studio SPA-side editor for the SendMail HTML body.
 *
 * This launcehs an iframe with the email template SPA, and communicates with it
 * by posting messages.
 */

TypeScript.editors.push(SendMailBodyEditor)
function SendMailBodyEditor(props: { obj: SendMail }) {
    const template = EmailTemplate.templates.find((t) => t.name === props.obj.template)

    const [code, setCode, setCodeLocally] = useBoundState(props.obj, "body")

    const staticUrl = `${server()}/static/${template?.htmlFileName}`
    const hotReloadUrl = `http://localhost:3001/${template?.htmlFileName}`

    const [childOrigin, setChildOrigin] = useState(staticUrl)

    // When clicking Ctrl+H, toggle between hot reload and static URL
    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "h") {
                setChildOrigin((prev) => (prev === hotReloadUrl ? staticUrl : hotReloadUrl))
            }
        }

        window.addEventListener("keydown", handler)

        return () => {
            window.removeEventListener("keydown", handler)
        }
    }, [hotReloadUrl, staticUrl])

    const trigger = Assert<StudioTrigger>(useDocument())
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [error, setError] = useState<string | undefined>(undefined)
    const [markdown, setMarkdown] = useState<string | undefined>(undefined)

    const updateEditor = useCallback(async () => {
        try {
            const { ast, sections } = await postSendMailParseAndEvaluate({
                sm: props.obj as any,
                trigger: trigger as any,
            })

            if (typeof sections === "string") {
                // The template returned a Markdown string, let's display it a such
                setMarkdown(sections)
            } else {
                setMarkdown(undefined)
            }

            // Send a message back to the child iframe
            iframeRef.current?.contentWindow?.postMessage(
                { update: { sections, ast } },
                childOrigin
            )
            setError(undefined)
        } catch (e: any) {
            setError(e.detail ?? e.message)
        }
    }, [iframeRef, props.obj, trigger, childOrigin])

    const debounceTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)

    useEffect(() => {
        const handler = async (event: MessageEvent<any>) => {
            if (childOrigin.startsWith(event.origin)) {
                if (event.data.status === "ready") await updateEditor()
                if (event.data.update) {
                    function pushAst(newAst: any, update: boolean) {
                        if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current)
                        debounceTimeoutRef.current = setTimeout(async () => {
                            const newCode = SerializeAST(newAst)
                            const version = updateVersion++
                            const prettifiedCode = await postSendMailPrettifyCode({
                                code: newCode,
                            })
                            if (version === updateVersion - 1) setCode(prettifiedCode.code)
                            if (update) await updateEditor()
                        }, 300)
                    }
                    if ("ast" in event.data.update) {
                        const newAst = event.data.update.ast
                        pushAst(newAst, false)
                    }
                    if (event.data.commit) {
                        if ("ast" in event.data.commit) {
                            const commitedAst = event.data.commit.ast
                            pushAst(commitedAst, true)
                        }
                    }
                }
            }
        }

        // Listen for messages from the iframe
        window.addEventListener("message", handler)

        return () => {
            window.removeEventListener("message", handler)
            // We do NOT want to remove the debounceTimeoutRef.current here,
            // as it would cause the debounce to be reset every time this hook
            // is called, which may lose some updates.
        }
    }, [updateEditor, childOrigin, code, setCode, setCodeLocally])

    useEffect(() => {
        if (!template) {
            // If there is no template, we won't load the iframe and will just
            // display the markdown. We still need to evaluate the code.
            void updateEditor()
        }
    }, [template, updateEditor, code])

    if (!template || markdown) {
        return (
            <div style={{ maxHeight: 1024, overflowY: "auto" }}>
                <MarkdownView value={markdown} />
            </div>
        )
    }

    if (error) {
        return (
            <div
                style={{
                    padding: 16,
                    backgroundColor: ColorStyles.warning[100],
                    border: `1px solid ${ColorStyles.warning[400]}`,
                    color: ColorStyles.warning[700],
                    borderRadius: 4,
                }}
            >
                Unable to preview email template: {error}
            </div>
        )
    }

    return (
        <div style={{ position: "relative" }}>
            {server().includes("localhost") && (
                <button
                    style={{
                        padding: 4,
                        borderRadius: 4,
                        backgroundColor: "lightblue",
                        position: "absolute",
                        top: 6,
                        right: 6,
                        fontSize: 8,
                    }}
                    onClick={() =>
                        setChildOrigin((prev) => (prev === hotReloadUrl ? staticUrl : hotReloadUrl))
                    }
                >
                    {childOrigin === hotReloadUrl ? "Static mode" : "Hot reload mode"}
                </button>
            )}
            <iframe
                key={childOrigin}
                ref={iframeRef}
                src={childOrigin}
                width="100%"
                height="1024"
                style={{ border: "none" }}
            />
        </div>
    )
}
