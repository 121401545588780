import React, { CSSProperties, useState } from "react"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { useHover } from "../../../../../packages/hooks/useHover"
import { Localized } from "../../../../../packages/localization/Localized"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import { Markdown } from "../../../../../reactor/Types/Primitives"
import { PartyMeal, PartyPackageByVenue } from "../../client"
import { colors } from "../../colors"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { Breadcrumbs } from "./Breadcrumbs"
import { Footer } from "./Footer"
import { EditablePosition } from "../../../../../packages/editing/EditablePosition"
import { Image } from "../../../../../reactor/Types/File"

export function MealPicker({
    partyPackage,
    style,
    section,
    setMealOption,
    mealOption,
}: {
    partyPackage: PartyPackageByVenue
    style?: CSSProperties
    section: {
        mealOptions?: Localized<string>
        mealPickerSubtitle: Localized<Markdown>
        mealPickerSticker?: Image
        mealPickerStickerOffsetX: number
        mealPickerStickerOffsetY: number
        selectedText: Localized<string>
    }
    setMealOption: (mealOption: PartyMeal) => void
    mealOption?: PartyMeal
}) {
    const [selectedIndex, setSelectedIndex] = useState(
        mealOption ? partyPackage.availableMeals.indexOf(mealOption) : -1
    )

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 64,
                ...style,
            }}
        >
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {section.mealPickerSticker && (
                    <div style={{ position: "relative" }}>
                        <EditablePosition
                            obj={section}
                            xProp="mealPickerStickerOffsetX"
                            yProp="mealPickerStickerOffsetY"
                        >
                            <img src={ImageToUrl(section.mealPickerSticker, { width: 96 })} />
                        </EditablePosition>
                    </div>
                )}
                <h2 style={{ marginTop: 64, marginBottom: 16 }}>
                    <EditableText obj={section} prop="mealOptions" defaultText="Meal options" />
                </h2>
                <EditableText
                    obj={section}
                    prop="mealPickerSubtitle"
                    defaultText="Add some extra flavour to your party"
                    isLocalized={true}
                    isMarkdown={true}
                />
                <Breadcrumbs page={"package"} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {partyPackage.availableMeals.map((m, i) => (
                        <MealOption
                            key={i}
                            meal={m}
                            index={i}
                            setIndex={setSelectedIndex}
                            selected={i === selectedIndex}
                            selectedText={section.selectedText}
                        />
                    ))}
                </div>
            </div>
            <Footer
                disabled={selectedIndex === -1}
                onClick={() => {
                    setMealOption(partyPackage.availableMeals[selectedIndex])
                }}
            />
        </div>
    )
}
function MealOption({
    meal,
    setIndex,
    selected,
    selectedText,
    index,
}: {
    meal: PartyMeal
    setIndex: (index: number) => void
    selected: boolean
    selectedText: Localized<string>
    index: number
}) {
    const { hover, hoverProps } = useHover()
    const localize = useLocalize()
    return (
        <div
            onClick={() => setIndex(index)}
            {...hoverProps}
            style={{
                position: "relative",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 300,
                marginLeft: 8,
                marginRight: 8,
                marginTop: 32,
                marginBottom: 16,
                paddingBottom: 16,
                cursor: "pointer",
                backgroundColor: "white",
            }}
        >
            <img
                style={{
                    width: 300,
                    height: 200,
                    objectFit: "cover",
                    objectPosition: "center",
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    borderBottom: selected || hover ? `8px solid ${colors.pinkFlirt}` : undefined,
                }}
                src={meal.image ? ImageToUrl(meal.image, { width: 300 }) : undefined}
            />
            <div
                className="bold"
                style={{
                    marginTop: 24,
                    fontSize: 32,
                    fontFamily: "GT-Haptik-Black",
                    textAlign: "center",
                    paddingLeft: 16,
                    paddingRight: 16,
                }}
            >
                {localize(meal.name)}
            </div>
            {meal.description && (
                <div
                    style={{
                        marginTop: 12,
                        marginBottom: 64,
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "center",
                        paddingLeft: 16,
                        paddingRight: 16,
                    }}
                >
                    {localize(meal.description)}
                </div>
            )}
            {selected && (
                <div
                    className="bold"
                    style={{
                        position: "absolute",
                        bottom: 24,
                        left: 0,
                        right: 0,
                        fontSize: 22,
                        fontFamily: "GT-Haptik-Black",
                        textAlign: "center",
                        color: colors.pinkFlirt,
                    }}
                >
                    {localize(selectedText)}
                </div>
            )}
        </div>
    )
}
