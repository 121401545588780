import { useIsEditing } from "../../../../packages/editing/EditableContext"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { HTML } from "../../../../reactor/Types/Primitives/HTML"
import { Section } from "../../../../packages/editing/Section"

Section(CustomHTML)
function CustomHTML(section: { height: number; html: Localized<HTML> }) {
    const localize = useLocalize<HTML>()
    const editing = useIsEditing()
    return (
        <div style={{ height: section.height ?? 300, pointerEvents: editing ? "none" : undefined }}>
            <iframe
                style={{ width: "100%", height: "100%" }}
                srcDoc={`<html><body>${localize(section.html).valueOf()}</body></html>`}
            />
        </div>
    )
}
