// Adyen React Native App environments
// source: https://github.com/Adyen/adyen-react-native/blob/develop/src/Core/configuration.ts#L5
// Used as default Adyen environment
export type AppEnv = (typeof appEnvironments)[number]
export function AppEnv(type: string): AppEnv {
    const appEnv = type as AppEnv
    if (appEnvironments.includes(appEnv)) return appEnv
    throw new Error(`${type} is not a recognized adyen app environment`)
}
const appEnvironments = ["test", "live-eu", "live-us", "live-au", "live-apse", "live-in"] as const

// Adyen Web environments
// source: https://github.com/Adyen/adyen-web/blob/main/packages/lib/src/core/types.ts#L21
export type WebEnv = (typeof webEnvironments)[number]
export function WebEnv(type: string): WebEnv {
    const webEnv = type as WebEnv
    if (webEnvironments.includes(webEnv)) return webEnv
    throw new Error(`${type} is not a recognized adyen web environment`)
}
const webEnvironments = ["test", "live", "live-us", "live-au", "live-apse", "live-in"] as const

// Convert Adyen app environment to Adyen web environment
export function appEnvToWebEnv(environment: AppEnv): WebEnv {
    const appEnv = AppEnv(environment)
    if (appEnv === "live-eu") return "live"
    return appEnv as WebEnv
}
