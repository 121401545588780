import { GetReflectionInfo } from "../ReflectionInfo"
import { LocaleKey } from "../../packages/localization/Locale"
import { ParseTagValue } from "../../packages/localization/client-side/TagParser"
import { Uuid } from "./Primitives/Uuid"
import {
    GetTypeProps,
    IntersectionType,
    ObjectType,
    Type,
    IsAnyType,
    GetTypeAlias,
    IsStringType,
    IsUnionType,
    IsUndefinedType,
    IsNumberType,
    IsBooleanType,
    IsIntersectionType,
    IsUnknownType,
    IsNullType,
    IsArrayType,
    IsObjectType,
    IsFileType,
    IsVoidType,
    TypeToString,
    IsNeverType,
    Property,
} from "./Type"
import { prettyCamel } from "../Helpers"

export type Defaults = {
    string?: any
    number?: any
    boolean?: any
    null?: any
    file?: File
    array?(item: Type, defaults: Defaults, locale: LocaleKey): any
    object?(type: ObjectType, defaults: Defaults, locale: LocaleKey): any
    intersection?(type: IntersectionType, defaults: Defaults, locale: LocaleKey): any
    uuid?(type: Type): any
    [alias: string]: any
}

export function CreateDefaultProps(
    type: ObjectType | IntersectionType,
    defaults: Defaults,
    locale: LocaleKey
) {
    const res = {} as any
    GetTypeProps(type).forEach((prop) => {
        const value = CreateDefaultProp(prop, defaults, locale)
        if (value !== undefined) res[prop.name] = value
    })
    return res
}

export function CreateDefaultProp(prop: Property, defaults: Defaults, locale: LocaleKey) {
    if (prop.tags?.defaultFile) {
        const defaultFiles = GetReflectionInfo().defaultFiles
        if (!defaultFiles) throw new Error("No default files found")

        const file = defaultFiles[prop.tags.defaultFile]?.file
        if (!file) throw new Error(`No default file found for ${prop.tags.defaultFile}`)

        return file
    }

    if (prop.tags?.serial) {
        // -1 = PGTable.SerialInteger default. We don't want to import
        // `PGTable` in this file, so hardcoding it.
        return -1
    }

    if (typeof prop.type === "object" && prop.type.reference) {
        return // Do not fill in bogus Uuid/key
    }
    const def =
        prop.tags?.default !== undefined
            ? ParseTagValue("default", prop.tags?.default, prop.type)
            : undefined

    if (def !== undefined) {
        return def
    } else {
        if (!prop.optional) {
            return CreateDefault(prop.type, defaults, locale)
        }
    }
}

export const DefaultDefaults = {
    string: "",
    number: 0,
    boolean: false,
    null: null,
    array(item: Type, defaults: Defaults): any[] {
        return []
    },
    object(type: ObjectType, defaults: Defaults, locale: LocaleKey): any {
        return CreateDefaultProps(type, defaults, locale)
    },
    intersection(type: IntersectionType, defaults: Defaults, locale: LocaleKey): any {
        return type.intersection
            .map((t) => CreateDefault(t, defaults, locale))
            .reduce((a, b) => {
                if (a !== null && b !== null && typeof a === "object" && typeof b === "object")
                    return Object.assign(a, b)
                else {
                    // Not an object - that means its some form of primitive type where both a and b
                    // are valid values. Let's return one of them
                    return a
                }
            })
    },
    uuid(type: Type) {
        if (typeof type === "object" && type.reference) {
            return undefined
        }
        return Uuid()
    },
}

/**
 * Default values with placeholders. This will use the pretty-printed property
 * name as a placeholder for strings.
 */
export const PlaceholderDefaults = {
    ...DefaultDefaults,
    string: "string",
    object(type: ObjectType, defaults: Defaults, locale: LocaleKey): any {
        const props = CreateDefaultProps(type, defaults, locale)
        for (const p of GetTypeProps(type)) {
            if (
                props[p.name] === "" ||
                props[p.name] === "string" ||
                (p.type === "string" && p.optional)
            ) {
                props[p.name] = prettyCamel(p.name, true)
            }
        }
        return props
    },
}

export const UninitializedDefaults: Defaults = {
    string: undefined,
    number: undefined,
    boolean: false,
    array() {
        return []
    },
    object(type: ObjectType, defaults: Defaults, locale: LocaleKey): any {
        return CreateDefaultProps(type, defaults, locale)
    },
    intersection(type: IntersectionType, defaults: Defaults, locale: LocaleKey): any {
        return type.intersection
            .map((t) => CreateDefault(t, defaults, locale))
            .reduce((a, b) => {
                if (a !== null && b !== null && typeof a === "object" && typeof b === "object")
                    return Object.assign(a, b)
                else {
                    // Not an object - that means its some form of primitive type where both a and b
                    // are valid values. Let's return one of them
                    return a
                }
            })
    },
    uuid(type: Type) {
        if (typeof type === "object" && type.reference) {
            return undefined
        }
        return Uuid()
    },
}

/** Creates a default instance of the given type. */
export function CreateDefault(
    type: Type,
    defaults: Defaults = DefaultDefaults,
    /** The locale that will be used as the only initial key in any `Localized<T>` value. */
    locale = LocaleKey.en
): any {
    const alias = GetTypeAlias(type)
    if (alias && alias in defaults) {
        return defaults[alias]
    }

    if (alias === "Uuid") {
        return defaults.uuid ? defaults.uuid(type) : Uuid()
    }

    if (typeof type === "object" && alias === "Localized" && type.typeArgs) {
        // Localized values must have at least one locale defined to be valid
        return { [locale as any]: CreateDefault(type.typeArgs[0], defaults, locale) }
    }

    if (IsAnyType(type) || IsUnknownType(type)) {
        return {}
    } else if (IsStringType(type)) {
        if (typeof type === "object" && type.string !== null) return type.string
        return "string" in defaults ? defaults.string : DefaultDefaults.string
    } else if (IsNumberType(type)) {
        if (typeof type === "object" && type.number !== null) return type.number
        return "number" in defaults ? defaults.number : DefaultDefaults.number
    } else if (IsBooleanType(type)) {
        if (typeof type === "object" && type.boolean !== null) return type.boolean
        return "boolean" in defaults ? defaults.boolean : DefaultDefaults.boolean
    } else if (IsNullType(type)) {
        return "null" in defaults ? defaults.null : DefaultDefaults.null
    } else if (IsUndefinedType(type)) {
        return undefined
    } else if (IsUnionType(type)) {
        // The TypeScript compiler sometimes re-uses union types internally,
        // e.g. if one type `"a" | "b"` is encountered first, and then `"b" |
        // "a"`, they will sometimes be merged into a single union type. This
        // means that the order of the union types in the source code definition
        // is not guaranteed to be the same as the order of the union types in
        // the reflection info. To make sure we generate consistent results, we
        // sort the union types by their string representation before picking
        // the first one.
        if (
            type.union.length > 0 &&
            type.union.every(
                (t): t is { string: string } =>
                    IsStringType(t) && typeof t === "object" && t.string !== null
            )
        ) {
            const sortedUnion = type.union.slice().sort((a, b) => a.string.localeCompare(b.string))
            return sortedUnion[0].string
        }

        return CreateDefault(type.union[0], defaults, locale)
    } else if (IsIntersectionType(type)) {
        return defaults.intersection
            ? defaults.intersection(type, defaults, locale)
            : DefaultDefaults.intersection(type, defaults, locale)
    } else if (IsArrayType(type)) {
        return defaults.array
            ? defaults.array(type.array, defaults, locale)
            : DefaultDefaults.array(type.array, defaults)
    } else if (IsObjectType(type)) {
        return defaults.object
            ? defaults.object(type, defaults, locale)
            : DefaultDefaults.object(type, defaults, locale)
    } else if (IsFileType(type)) {
        return defaults.file
    } else if (IsVoidType(type)) {
        return undefined
    } else if (IsNeverType(type)) {
        throw new Error("Cannot create a default value of type 'never'")
    }

    throw new Error("CreateDefault: Unhandled type: " + TypeToString(type))
}
